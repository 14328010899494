import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Pagination } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import EmptyRow from '@components/EmptyRow'
import Flex from '@components/Flex'
import PageTitle from '@components/PageTitle'
import SearchInput from '@components/SearchInput'
import Spinner from '@components/Spinner'
import Table from '@components/Table'
import Td from '@components/Td'
import Template from '@components/Template'
import Tr from '@components/Tr'
import { successMessages } from '@constants/feedbackMessages'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import { categoriesApi } from '@services/Categories'
import { loadingModal, openAlertModal } from '@store/ducks/modal'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { useFilters, useInputBounce, useQuery } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	tag: 'font-medium text-blue-600 underline underline-offset-2',
	divider: 'mt-[32px] mb-[12px]',
	avatar: 'rounded-full max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]',
}

function Categories() {
	const pages = [{ name: 'Categorias', href: '#', current: true }]
	const dispatch = useAppDispatch()
	const query = useQuery()
	const navigate = useNavigate()

	const pageQuery = Number(query.get('page')) || 1
	const searchQs = query.get('search') || ''

	const [search, setSearch] = useState(decodeURIComponent(searchQs))
	const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchQs))
	const [page, setPage] = useState(pageQuery)

	const { data, isLoading } = categoriesApi.useListQuery({
		page,
		search: encodeURIComponent(searchQuery),
	})

	const [
		deleteCategories,
		{ isLoading: loadingDelete, isSuccess: deleteSuccess },
	] = categoriesApi.useDeleteMutation()

	const searchCallback = useCallback(
		(value: string) => {
			navigate('/categories?page=1')
			setSearchQuery(value)
		},
		[navigate]
	)

	useEffect(() => {
		dispatch(loadingModal({ loading: loadingDelete }))

		if (deleteSuccess) {
			dispatch(notificationCreators.show(successMessages.deleteCategories))
		}
	}, [dispatch, loadingDelete, deleteSuccess])

	useEffect(() => {
		setPage(pageQuery)
	}, [pageQuery])

	useInputBounce(search, searchCallback)

	useFilters({
		search: searchQuery,
		fallbackUrl: '/categories',
	})

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Categorias' />

				<Flex className={styles.searchContainer}>
					<SearchInput
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='flex-1'
						placeholder='Buscar Categoria'
					/>

					<Button onClick={() => navigate(`/categories/cadastrar-categoria`)}>
						Adicionar Categoria
					</Button>
				</Flex>

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<Table th={[{ label: 'id' }, { label: 'nome' }, { label: 'ações' }]}>
						{data?.data && data?.data.length > 0 ? (
							data?.data.map(({ id, name }) => (
								<Tr key={id}>
									<Td>{id}</Td>
									<Td className='w-[75%] px-6'>
										<Flex className='items-center gap-4'>{name}</Flex>
									</Td>

									<Td>
										<Flex className='items-center gap-4'>
											<Button
												className='!px-2 !py-2'
												leftIcon={<PencilAltIcon />}
												disabled={false}
												variant='outline'
												onClick={() =>
													navigate(`/categories/editar-categoria/${id}`)
												}
											>
												<></>
											</Button>

											<Button
												className='!px-2 !py-2'
												leftIcon={<TrashIcon />}
												disabled={false}
												variant='outline'
												onClick={() => {
													const removeCategories = {
														title: 'Remover Categoria',
														message:
															'Tem certeza que deseja remover a categoria?',
														buttonLabel: 'Remover Categoria',
														onClick: () =>
															deleteCategories({
																id,
															}),
													}

													dispatch(openAlertModal(removeCategories))
												}}
											>
												<></>
											</Button>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<EmptyRow tdAmount={3} message='Nenhum turma encontrada' />
						)}
					</Table>
				)}

				<Divider className={styles.divider} />
				{data?.data && data?.data?.length > 0 && (
					<Pagination
						pageCount={data?.meta.lastPage || 0}
						maxAmount={data?.meta.total || 0}
						perPage={data?.meta.perPage || 0}
					/>
				)}
			</Template>
		</>
	)
}

export default Categories
