import React, { useEffect, useMemo } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { FileInput, Input, InputGroup, Spinner } from '@components'
import Breadcrumb from '@components/Breadcrumb'
import Button from '@components/Button'
import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import Template from '@components/Template'
import { successMessages } from '@constants/feedbackMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { addCategoriesSchema } from '@schemas/addCategories.schema'
import { categoriesApi } from '@services/Categories'
import { notificationCreators } from '@store/ducks/notification'
import { useAppDispatch } from '@store/hooks'
import { inputIsValid } from '@utils/helpers'
import { createBreadcrumbLink, useCreateBreadcrumb } from '@utils/hooks'

const styles = {
	searchContainer: 'gap-[8px] mb-[32px] items-end flex-wrap',
	divider: 'mt-[32px] mb-[12px]',
}

type Inputs = {
	name: string
	image: Array<File>
}

function EditCategories() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const { id } = useParams<{
		id: string
	}>()

	const baseUrl = `/categories/editar-categoria/${id}`

	const allPages = useMemo(() => {
		return [
			createBreadcrumbLink(`Categorias`, `/categories`, 1),
			createBreadcrumbLink(`Editar Categoria`, baseUrl, 2),
		]
	}, [baseUrl])

	const pages = useCreateBreadcrumb(allPages)

	const [getCategories, { data, isLoading }] = categoriesApi.useShowMutation()

	useEffect(() => {
		if (id) {
			getCategories({ id: Number(id) as number })
		}
	}, [id, getCategories])

	const [editCategories, { isSuccess }] = categoriesApi.useEditMutation()

	const {
		register,
		handleSubmit,
		control,
		setValue,
		reset,
		formState: { errors, touchedFields },
	} = useForm<Inputs>({
		mode: 'onBlur',
		resolver: yupResolver(addCategoriesSchema),
	})

	const onSubmit: SubmitHandler<Inputs> = (dataEdit) => {
		const newData = {
			name: dataEdit.name,
			image: dataEdit.image,
		}

		editCategories({
			...newData,
			id,
		})
	}

	useEffect(() => {
		if (data) {
			setValue('name', data?.name as string)
		}
	}, [data, setValue])

	useEffect(() => {
		if (isSuccess) {
			navigate('/categories')
			dispatch(notificationCreators.show(successMessages.updatedCategories))
			reset()
		}
	}, [isSuccess, dispatch, navigate, reset])

	return (
		<>
			<Template>
				<Breadcrumb pages={pages} />
				<PageTitle title='Editar Categoria' />

				{isLoading ? (
					<div className='flex justify-center'>
						<Spinner size='md' />
					</div>
				) : (
					<form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
						<div className='grid grid-cols-2 gap-4'>
							<InputGroup label='Título da Categoria' id='name'>
								<Input
									type='text'
									{...register('name')}
									isValid={inputIsValid(errors.name, touchedFields.name)}
								/>
							</InputGroup>
							<Controller
								control={control}
								name='image'
								render={() => {
									return (
										<FileInput
											className='shadow-3 block w-full sm:text-sm border-gray-300 rounded-md placeholder:text-gray px-[13px] py-[6.5px]'
											mt='mt-4'
											accept='.png,.jpg'
											onChange={(v) => {
												setValue('image', v)
											}}
										>
											Imagem da turma
										</FileInput>
									)
								}}
							/>
						</div>

						<div className='flex gap-4 mt-6 mb-10'>
							<Button
								variant='outline'
								onClick={() => navigate(`/categories?page=1`)}
							>
								Cancelar
							</Button>
							<Button type='submit'>Editar Categoria</Button>
						</div>
					</form>
				)}
				<Divider className={styles.divider} />
			</Template>
		</>
	)
}

export default EditCategories
