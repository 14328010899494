import { BASE_URL } from '@constants/api'
import {
	AddCategoriesRequest,
	ListCategoriesRequest,
	ListCategoriesResponse,
	ShowCategoriesResponse,
	UpdateCategoriesRequest,
} from '@models/categories.model'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setAuthorization } from '@utils/helpers'

export const categoriesApi = createApi({
	reducerPath: 'categoriesTurmaApi',
	tagTypes: ['Categories'],
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: setAuthorization,
	}),
	endpoints: (builder) => ({
		list: builder.query<ListCategoriesResponse, ListCategoriesRequest>({
			query: ({ page = '1', search = '', limit = 20 }) => ({
				url: `/categories?page=${page}&search=${search}&limit=${limit}`,
				method: 'GET',
			}),
			providesTags: ['Categories'],
		}),
		show: builder.mutation<ShowCategoriesResponse, { id: number }>({
			query: ({ id }) => ({
				url: `/categories/${id}`,
				method: 'GET',
			}),
		}),
		add: builder.mutation<void, AddCategoriesRequest>({
			query: (newData) => {
				const formData = new FormData()

				formData.append('name', newData.name)
				formData.append('image', newData.image ? newData.image[0] : '')

				return {
					url: `/categories`,
					body: formData,
					method: 'POST',
				}
			},
			invalidatesTags: ['Categories'],
		}),
		edit: builder.mutation<void, UpdateCategoriesRequest>({
			query: ({ id, ...data }) => {
				const formData = new FormData()

				formData.append('name', data.name)
				formData.append('image', data.image ? data.image[0] : '')

				return {
					url: `/categories/${id}`,
					body: formData,
					method: 'PUT',
				}
			},
			invalidatesTags: ['Categories'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `/categories/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Categories'],
		}),
	}),
})
