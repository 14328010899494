import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { UserRoles } from '@models/auth.model'
import { App, Login } from '@pages'
import Announcement from '@pages/Announcement'
import Banca from '@pages/Banca'
import Categories from '@pages/Categories'
import CreateCategories from '@pages/Categories/CreateCategories'
import EditCategories from '@pages/Categories/EditCategories'
import Classes from '@pages/Classes'
import CreateClasses from '@pages/Classes/CreateClasses'
import EditClasses from '@pages/Classes/EditClasses'
import Clients from '@pages/Clients'
import IndividualClient from '@pages/Clients/IndividualClient'
import CommentsModule from '@pages/CommentsModule'
import CommentsQuestions from '@pages/CommentsQuestions'
import ErrorsModule from '@pages/ErrorsModule'
import ErrorsQuestions from '@pages/ErrorsQuestions'
import Institution from '@pages/Institution'
import ListLeads from '@pages/Leads'
import LeadsPopups from '@pages/LeadsPopups'
import Librarie from '@pages/Librarie'
import IndividualLibrarie from '@pages/Librarie/Individuallibrarie'
import Loading from '@pages/Loading'
import ListModules from '@pages/Modules'
import CreateContents from '@pages/Modules/contents/CreateContent'
import EditContents from '@pages/Modules/contents/EditContent'
import IndividualModule from '@pages/Modules/module'
import Occupation from '@pages/Occupation'
import Popups from '@pages/Popups'
import { Profile } from '@pages/Profile'
import EditPromotional from '@pages/Promotional/EditPromotional'
import { ListQuestions } from '@pages/Questions'
import CreateQuestion from '@pages/Questions/CreateQuestion'
import EditQuestion from '@pages/Questions/EditQuestion'
import QuestionGenerator from '@pages/QuestionsGenerator'
import EditQuestionGenerator from '@pages/QuestionsGenerator/EditQuestion'
import ReviewQuestions from '@pages/QuestionsGenerator/ReviewQuestions'
import QuestionsReport from '@pages/QuestionsReport'
import ListCategories from '@pages/Simulated'
import IndividualCategory from '@pages/Simulated/IndividualCategory'
import IndividualSimulated from '@pages/Simulated/IndividualSimulated'
import AddQuestion from '@pages/Simulated/IndividualSimulated/AddQuestion'
import StatusMigrations from '@pages/StatusMigration'
import ListUsers from '@pages/Users'
import { ProfileUser } from '@pages/Users/Profile'
import Year from '@pages/Year'
import { authApi } from '@services/AuthApi'
import { logout, setUser, signInSuccess } from '@store/ducks/auth'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { LocalStorage } from '@utils/helpers'

import { disciplineRoutes } from './discipline'

type RouteType = Array<{
	path: string
	element: () => JSX.Element
	isPrivate: boolean
	roles?: Array<UserRoles>
}>

type PrivateRouteProps = {
	children: React.ReactElement | null
	roles: Array<UserRoles> | undefined
}

function Logout() {
	const dispatch = useAppDispatch()

	dispatch(logout())

	return <Navigate to='/' />
}

function PrivateRoute({ children, roles }: PrivateRouteProps) {
	const {
		auth: { signed, user },
	} = useAppSelector((state) => state)

	const dispatch = useAppDispatch()

	const location = useLocation()
	const [loading, setLoading] = useState(true)

	const storage = new LocalStorage()
	const token = storage.get('token')

	const [getProfile, { data, isLoading }] = authApi.useProfileMutation()

	useEffect(() => {
		if (token && !signed) {
			setLoading(true)
		}

		if (signed) {
			getProfile()
		}
	}, [signed, token, getProfile])

	useEffect(() => {
		if (token && signed) {
			if (data) {
				dispatch(setUser(data))
			}

			if (user) {
				setLoading(false)
			}
		}
	}, [data, dispatch, token, signed, user])

	useEffect(() => {
		if (!token && !signed) {
			setLoading(false)
		}
	}, [token, signed])

	if (loading || isLoading) {
		return <Loading />
	}

	if (roles && user) {
		let hasPermission = false

		user.roles.forEach((role) => {
			if (roles.includes(role.name)) {
				hasPermission = true
			}
		})

		if (!hasPermission) {
			return <Navigate to='/profile' />
		}
	}

	return signed ? (
		children
	) : (
		<Navigate to='/login' state={{ redirect: location.pathname }} />
	)
}

const routes: RouteType = [
	{
		path: '/login',
		element: Login,
		isPrivate: false,
	},
	{
		path: '/',
		element: App,
		isPrivate: true,
		roles: ['Admin'],
	},
	...disciplineRoutes,
	{
		path: '/banca',
		element: Banca,
		isPrivate: true,
		roles: ['Admin', 'Content Creator', 'Question Creator'],
	},
	{
		path: '/instituicao',
		element: Institution,
		isPrivate: true,
		roles: ['Admin', 'Content Creator', 'Question Creator'],
	},
	{
		path: '/ano',
		element: Year,
		isPrivate: true,
		roles: ['Admin', 'Content Creator', 'Question Creator'],
	},
	{
		path: '/cargo',
		element: Occupation,
		isPrivate: true,
		roles: ['Admin', 'Content Creator', 'Question Creator'],
	},
	{
		path: '/categories',
		element: Categories,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/categories/cadastrar-categoria',
		element: CreateCategories,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/categories/editar-categoria/:id',
		element: EditCategories,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/turmas',
		element: Classes,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/turmas/cadastrar-turma',
		element: CreateClasses,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/turmas/editar-turma/:id',
		element: EditClasses,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/comunicados',
		element: Announcement,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/comunicados/turma',
		element: Announcement,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/gerador-questoes',
		element: QuestionGenerator,
		isPrivate: true,
		roles: ['Admin', 'Question Creator'],
	},
	{
		path: '/revisar-questoes',
		element: ReviewQuestions,
		isPrivate: true,
		roles: ['Admin', 'Question Creator'],
	},
	{
		path: '/revisar-questoes/:id',
		element: EditQuestionGenerator,
		isPrivate: true,
		roles: ['Admin', 'Question Creator'],
	},
	{
		path: '/modulos',
		element: ListModules,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/modulos/:id/',
		element: IndividualModule,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/modulos/:id/criar-conteudo',
		element: CreateContents,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/modulos/:id/conteudo/:idContent/editar-conteudo',
		element: EditContents,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/bibliotecas',
		element: Librarie,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/bibliotecas/:id',
		element: IndividualLibrarie,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/popups',
		element: Popups,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/comentarios-modulo',
		element: CommentsModule,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/comentarios-questoes',
		element: CommentsQuestions,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/erros-modulo',
		element: ErrorsModule,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/erros-questoes',
		element: ErrorsQuestions,
		isPrivate: true,
		roles: ['Admin'],
	},

	{
		path: '/categorias',
		element: ListCategories,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/questoes',
		element: ListQuestions,
		isPrivate: true,
		roles: ['Admin', 'Question Creator'],
	},
	{
		path: '/questoes-relatorio',
		element: QuestionsReport,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/questoes/criar-questao',
		element: CreateQuestion,
		isPrivate: true,
		roles: ['Admin', 'Question Creator'],
	},
	{
		path: '/questoes/editar-questao/:id',
		element: EditQuestion,
		isPrivate: true,
		roles: ['Admin', 'Question Creator'],
	},
	{
		path: '/categorias/:id',
		element: IndividualCategory,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/categorias/:id/simulado/:idSimulated',
		element: IndividualSimulated,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/categorias/:id/simulado/:idSimulated/adicionar-questao',
		element: AddQuestion,
		isPrivate: true,
		roles: ['Admin', 'Content Creator'],
	},
	{
		path: '/promocional/',
		element: EditPromotional,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/Leads/',
		element: ListLeads,
		isPrivate: true,
		roles: ['Admin'],
	},
	// {
	// 	path: '/promocional/:id',
	// 	element: EditPromotional,
	// 	isPrivate: true,
	// 	roles: ['Admin'],
	// },
	// {
	// 	path: '/promocional/criar',
	// 	element: AddPromotional,
	// 	isPrivate: true,
	// 	roles: ['Admin'],
	// },
	{
		path: '/clientes/',
		element: Clients,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/clientes/ativos',
		element: Clients,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/clientes/:id',
		element: IndividualClient,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/clientes/:id/informacoes',
		element: IndividualClient,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/usuarios',
		element: ListUsers,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/usuarios/:id',
		element: ProfileUser,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/profile',
		element: Profile,
		isPrivate: true,
	},
	{
		path: '/statusMigrations',
		element: StatusMigrations,
		isPrivate: true,
		roles: ['Admin'],
	},
	{
		path: '/Leads-popups/',
		element: LeadsPopups,
		isPrivate: true,
		roles: ['Admin'],
	},
]

export function AppRoutes() {
	const storage = new LocalStorage()
	const token = storage.get('token')
	const { pathname, search } = useLocation()

	const dispatch = useAppDispatch()

	// eslint-disable-next-line func-names
	document.addEventListener('wheel', function () {
		if (
			(document?.activeElement as HTMLInputElement | null)?.type === 'number'
		) {
			return (document.activeElement as HTMLInputElement)?.blur()
		}

		return undefined
	})

	useEffect(() => {
		if (token && pathname !== '/logout') {
			dispatch(signInSuccess({ token }))
		}
	}, [dispatch, token, pathname])

	if (pathname !== '/' && pathname.match(/.*\/$/)) {
		return (
			<Navigate
				replace
				to={{
					pathname: pathname.replace(/\/+$/, ''),
					search,
				}}
			/>
		)
	}

	return (
		<Routes>
			{routes.map((route) => {
				if (route.isPrivate) {
					return (
						<Route
							key={route.path}
							{...route}
							element={
								<PrivateRoute roles={route.roles}>
									<route.element />
								</PrivateRoute>
							}
						/>
					)
				}

				return <Route key={route.path} {...route} element={<route.element />} />
			})}

			<Route path='/logout' element={<Logout />} />
			<Route path='*' element={<Navigate to='/' />} />
		</Routes>
	)
}
